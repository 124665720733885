import { graphql, HeadProps, PageProps } from 'gatsby'
import React, { useEffect } from 'react'

import FeatureOverview from '../components/FeatureOverview'
import Hero from '../components/Hero'
import ResourceCards from '../components/Homepage/ResourceCards'
import Layout from '../components/Layout'
import ProductPromoCard from '../components/ProductPromoCard'
import RelatedResources from '../components/RelatedResources'
import { SchemaMarkup } from '../components/SchemaMarkup/SchemaMarkup'
import { SchemaMarkupBreadcrumb } from '../components/SchemaMarkup/SchemaMarkupBreadcrumb'
import TrainingProgramRoles from '../components/TrainingProgramRoles'
import TrainingProgramStages from '../components/TrainingProgramStages'
import TrainstopSection from '../components/TrainstopSection'
import { useSplitTracking } from '../hooks/useSplitTracking'
import { useTrackSolutionPage } from '../utils/dataLayer'

const ProductOverviewPage = ({
  data,
  pageContext,
}: PageProps<
  Queries.productOverviewPageQuery,
  { filterType: string; slug: string }
>) => {
  const pageData = data?.contentstackPageProductOverview
  const parentPage = data?.parentCategoryPage?.nodes?.[0]

  const breadcrumbLinks = [{ text: pageData?.title || '', target: '' }]
  if (parentPage?.title && parentPage?.url) {
    breadcrumbLinks.unshift({
      text: parentPage?.title,
      target: parentPage?.url,
    })
  }

  const allProductPages = data?.productPromoPages?.nodes

  let resourcesByFilter
  switch (pageContext.filterType) {
    case 'Contentstack_topic_resource_type':
      resourcesByFilter = [
        ...(data?.resourceCardsByResourceTypeFilter?.nodes || []),
      ]
      break
    case 'Contentstack_topic_industry':
      resourcesByFilter = [
        ...(data?.resourceCardsByIndustryFilter?.nodes || []),
      ]
      break
    case 'Contentstack_topic_role':
      resourcesByFilter = [...(data?.resourceCardsByRoleFilter?.nodes || [])]
      break
    default:
      resourcesByFilter = []
      break
  }

  const allFeaturedResourceCards = data?.allFeaturedResourceCards?.nodes || []
  if (resourcesByFilter.length === 0) {
    allFeaturedResourceCards?.forEach((resource: any) =>
      resourcesByFilter.push(resource),
    )
  }

  const metaTitle =
    pageData?.metadata?.page_title ||
    pageData?.title ||
    pageData?.page_header?.[0]?.headline ||
    (pageData?.page_header?.[0]?.topic?.[0]?.__typename ===
      'Contentstack_topic_product_category' &&
      pageData?.page_header?.[0]?.topic?.[0].headline)
  const metaDesc =
    pageData?.metadata?.description ||
    pageData?.page_header?.[0]?.subheading ||
    (pageData?.page_header?.[0]?.topic?.[0]?.__typename ===
      'Contentstack_topic_product_category' &&
      pageData?.page_header?.[0]?.topic?.[0]?.subheading)
  const metaImg =
    pageData?.metadata?.image ||
    pageData?.page_header?.[0]?.feature_image ||
    (pageData?.page_header?.[0]?.topic?.[0]?.__typename ===
      'Contentstack_topic_product_category' &&
      pageData?.page_header?.[0]?.topic?.[0]?.feature_image)

  useTrackSolutionPage(pageContext.slug, data)

  const track = useSplitTracking()

  useEffect(() => {
    const salesAnalyticsPage =
      window.location.pathname.includes('sales-analytics')

    if (salesAnalyticsPage) {
      track('sales_analytics__pageview', undefined, {
        url: window.location.href,
      })
    }
    track('product_overview__pageview', undefined, {
      url: window.location.href,
    })
  }, [])

  return (
    <Layout
      breadcrumbLinks={breadcrumbLinks}
      description={metaDesc}
      image={metaImg}
      locale={pageData?.publish_details?.locale}
      slug={pageContext.slug}
      title={metaTitle}
    >
      <Hero content={pageData?.page_header?.[0]} />
      {pageData?.introduction?.[0] ? (
        <FeatureOverview
          body={
            pageData?.introduction?.[0]?.overview_description ||
            pageData.introduction?.[0]?.topic?.[0]?.overview_description
          }
          headline={
            pageData?.introduction?.[0]?.headline ||
            pageData.introduction?.[0]?.topic?.[0]?.overview_headline
          }
        />
      ) : null}
      {pageData?.optional_sections?.map((section: any) => (
        <React.Fragment key={section.id}>
          {section?.internal?.type ===
            'Contentstack_section_sales_training_program_stages' && (
            <TrainingProgramStages
              body={section.overview}
              categories={section.program_categories}
              headline={section.headline}
              locale={pageData?.publish_details?.locale}
            />
          )}
          {section?.internal?.type ===
            'Contentstack_section_product_category_links' && (
            <TrainingProgramStages
              body={section.short_description}
              categories={section}
              headline={section.headline}
              isProgramStage={false}
              locale={pageData?.locale}
            />
          )}
          {section?.internal?.type === 'Contentstack_section_train_stop' && (
            <TrainstopSection
              ctaText={section.cta_text}
              headline={section.headline}
              isHeroTrainStop={section.is_hero_train_stop}
              slides={section.slides}
            />
          )}
          {section?.internal?.type ===
            'Contentstack_section_feature_overview' && (
            <FeatureOverview
              body={
                section.overview_description ||
                section.topic?.[0]?.overview_description
              }
              headline={
                section.headline || section.topic?.[0]?.overview_headline
              }
              theme="White"
            />
          )}
          {section?.internal?.type ===
            'Contentstack_section_product_promo_card' && (
            <ProductPromoCard
              ctaText={section.cta_text}
              ctaUrl={
                allProductPages?.filter(
                  (page: any) =>
                    page.product?.[0]?.id === section.promoted_product?.[0]?.id,
                )?.[0]?.url
              }
              imagePosition={section.image_position}
              promotedProduct={section.promoted_product}
            />
          )}
          {section?.internal?.type ===
            'Contentstack_section_custom_promo_card' && (
            <ProductPromoCard
              ctaText={section.cta_link[0]?.text}
              ctaUrl={
                section.cta_link[0]?.external_url ||
                section.cta_link[0]?.internal_overview_page[0]?.pageUrl ||
                section.cta_link[0]?.anchor
              }
              imagePosition={section.image_position}
              promotedProduct={[
                {
                  id: section.id,
                  headline: section.title,
                  short_description: section.short_description,
                  feature_image: section.feature_image,
                },
              ]}
            />
          )}
          {section?.internal?.type ===
            'Contentstack_section_sales_training_program_roles' && (
            <TrainingProgramRoles
              headline={section.headline}
              roles={section.program_roles}
            />
          )}
          {section?.internal?.type ===
            'Contentstack_section_resource_card_set' && (
            <RelatedResources
              content={{
                related_resources: resourcesByFilter,
                ...section,
              }}
            />
          )}
          {section?.internal?.type ===
            'Contentstack_section_resource_card_carousel' && (
            <ResourceCards
              content={section}
              resources={allFeaturedResourceCards}
            />
          )}
        </React.Fragment>
      ))}
    </Layout>
  )
}

export const Head = ({ data }: HeadProps<Queries.productOverviewPageQuery>) => (
  <>
    <SchemaMarkup data={data.contentstackPageProductOverview?.schema_markup} />
    <SchemaMarkupBreadcrumb
      pageTitle={data?.contentstackPageProductOverview?.title}
      parent={{
        title: data?.parentCategoryPage?.nodes?.[0]?.title,
        pageUrl: data?.parentCategoryPage?.nodes?.[0]?.url,
      }}
    />
  </>
)

export default ProductOverviewPage

export const query = graphql`
  query productOverviewPage(
    $pageId: String
    $filterId: String
    $locale: String
    $parentCategoryId: String
  ) {
    contentstackPageProductOverview(id: { eq: $pageId }) {
      id
      title
      locale
      chinese_version_url
      publish_details {
        locale
      }
      metadata {
        page_title
        description
        image {
          description
          url
        }
      }
      schema_markup {
        ...SchemaMarkup
      }
      page_header {
        ...PageHeaderSection
      }
      introduction {
        ...FeatureOverviewSection
      }
      optional_sections {
        ... on Contentstack_section_sales_training_program_stages {
          ...SalesTrainingProgramStagesSection
        }
        ... on Contentstack_section_product_category_links {
          ...ProductCategoryLinksSection
        }
        ... on Contentstack_section_train_stop {
          ...TrainStopSection
        }
        ... on Contentstack_section_feature_overview {
          ...FeatureOverviewSection
        }
        ... on Contentstack_section_product_promo_card {
          ...ProductPromoCard
        }
        ... on Contentstack_section_custom_promo_card {
          ...CustomPromoCard
        }
        ... on Contentstack_section_sales_training_program_roles {
          ...SalesTrainingProgramRoles
        }
        ... on Contentstack_section_resource_card_set {
          ...RelatedResourcesSection
        }
        ... on Contentstack_section_resource_card_carousel {
          ...ResourceCardCarouselSection
        }
      }
    }

    resourceCardsByRoleFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { roles: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    resourceCardsByResourceTypeFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { resource_type: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    resourceCardsByIndustryFilter: allContentstackPageResource(
      filter: {
        publish_details: { locale: { eq: $locale } }
        resource: {
          elemMatch: { industry: { elemMatch: { id: { eq: $filterId } } } }
        }
      }
      limit: 15
    ) {
      nodes {
        id
        title
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    allFeaturedResourceCards: allContentstackPageResource(
      filter: { publish_details: { locale: { eq: $locale } } }
      limit: 15
    ) {
      nodes {
        id
        url
        resource {
          ...ResourceCardFragment
        }
      }
    }

    productPromoPages: allContentstackPageProduct(
      filter: { publish_details: { locale: { eq: $locale } } }
    ) {
      nodes {
        id
        url
        product {
          id
        }
      }
    }

    parentCategoryPage: allContentstackPageProductOverview(
      filter: {
        publish_details: { locale: { eq: $locale } }
        product_category: { elemMatch: { id: { eq: $parentCategoryId } } }
      }
    ) {
      nodes {
        title
        url
      }
    }
  }
`
