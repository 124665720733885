import Typography from '@material-ui/core/Typography'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import React from 'react'

import Link from '../Link'
import Wrapper from '../UI/Wrapper'
import { FeaturedRolesContainer, FeaturedRolesSection } from './styles'

interface TrainingProgramRolesProps {
  headline: string
  roles: {
    id: string
    url: string
    title: string
    reference: {
      short_description: string
    }
  }[]
}

const TrainingProgramRoles = (props: TrainingProgramRolesProps) => {
  const { headline, roles } = props

  return (
    <FeaturedRolesSection>
      <Wrapper>
        <Typography className="subhead" component="h2" variant="h2">
          {headline}
        </Typography>
        <FeaturedRolesContainer>
          {roles.map((role: any) => (
            <li key={role.id}>
              <Typography className="roleSubhead" component="h3" variant="h3">
                <Link directUrl={role.url}>
                  <>
                    {role.title}
                    <ArrowRightIcon className="arrow" />
                  </>
                </Link>
              </Typography>
              <Typography className="body" component="p" variant="subtitle1">
                {role.reference?.[0]?.short_description}
              </Typography>
            </li>
          ))}
        </FeaturedRolesContainer>
      </Wrapper>
    </FeaturedRolesSection>
  )
}

export default TrainingProgramRoles
