import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const FeaturedRolesSection = styled.section`
  background-color: #ffffff;
  padding: 88px 0 92px;

  .subhead {
    text-align: center;
    color: ${rspTheme.palette.secondary.main};
    margin-bottom: 48px;
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 48px 0;

    .subhead {
      font-size: 2.8rem;
      margin-bottom: 32px;
      text-align: left;
    }
  }
`

export const FeaturedRolesContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;

  .roleSubhead {
    margin-bottom: 20px;
    max-width: 12ch;
  }

  li {
    max-width: 200px;
  }

  a {
    color: ${rspTheme.palette.secondary.main};
    text-decoration-color: transparent;
    transition: 0.2s ease-in-out;
    position: relative;

    &:hover,
    &:focus {
      text-decoration-color: inherit;
    }

    .arrow {
      position: absolute;
      right: -24px;
      bottom: 2px;
    }
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    li {
      max-width: 440px;
    }

    .roleSubhead {
      font-size: 1.6rem;
      max-width: unset;
    }

    a {
      .arrow {
        bottom: -1px;
      }
    }
  }
`
